<template>
  <div>
    <div class="social-line social-line-big-icons social-line-white">
        <div class="container">
            <div class="row">
                <div class="col-md-2 ml-auto">
                    <a href="https://money-shield.co.uk/" target=_blank class="btn btn-neutral btn-icon btn-footer">
                        <img v-lazy="'img/icons/logo1.jpg'" alt="Money Shield">
                    </a>
                </div>
                <div class="col-md-2">
                    <a href="https://ico.org.uk/" target=_blank class="btn btn-neutral btn-icon">
                        <img v-lazy="'img/icons/logo2.png'" alt="Information Commissioner's Office" width="96" height="90">
                    </a>
                </div>
                <div class="col-md-2">
                    <a href="https://www.depositprotection.com/" target=_blank class="btn btn-neutral btn-icon">
                        <img v-lazy="'img/icons/logo3.png'" alt="Deposit Protection Service" width="80" height="80">
                    </a>
                </div>
                <div class="col-md-2">
                    <a href="https://www.tpos.co.uk/" target=_blank class="btn btn-neutral btn-icon">
                        <img v-lazy="'img/icons/logo4.jpg'" alt="The Property Ombudsman">
                    </a>
                </div>
                <div class="col-md-2">
                    <a href="https://www.tradingstandards.uk/" target=_blank class="btn btn-neutral btn-icon">
                        <img v-lazy="'img/icons/logo5.png'" alt="Trading Standards UK">
                    </a>
                </div>
                <div class="col-md-2 mr-auto">
                    <a href="https://www.propertymark.co.uk/" target=_blank class="btn btn-neutral btn-icon">
                        <img v-lazy="'img/icons/logo6.jpg'" alt="ARLA PropertyMark">
                    </a>
                </div>
            </div>
        </div>
    </div>
    
    <footer class="footer footer-big"
            :class="{[`footer-${type}`]: type}"
            :data-background-color="backgroundColor">
        <div class="container">
            <div class="content">
                <div class="row">
                    <div class="col-md-6">
                        <h5>Contact us</h5>
                        Address:<span class="tab"/>BSL (Burnley Sales & Lettings Ltd)<br>
                        <span class="tab-extra"/>78 Coal Clough Lane<br>
                        <span class="tab-extra"/>Burnley<br>
                        <span class="tab-extra"/>Lancashire<br>
                        <span class="tab-extra"/>BB11 4NW<br><br>
                        Telephone:<span class="tab-phone"/>01282 476 732<br>
                        Email:<span class="tab-email"/><a href="mailto:lettings@burnleysl.co.uk">lettings@burnleysl.co.uk</a><br>
                        <span class="tab-extra"/><a href="mailto:sales@burnleysl.co.uk">sales@burnleysl.co.uk</a>
                    </div>

                    <div class="col-md-6">
                    <nav>
                        <h5>About us</h5>
                        <ul class="links-vertical">
                            <li>
                                <a href="/privacy-policy">
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a href="/accreditations">
                                    Accreditations
                                </a>
                            </li>
                            <li>
                                <a href="/about-us">
                                    About Us
                                </a>
                            </li>
                        </ul>
                    </nav>
                    </div>
                </div>
            </div>
            <hr/>
            <ul class="social-buttons">
            <li>
                <a href="https://www.twitter.com/burnleysl/" target="_blank" class="btn btn-icon btn-neutral btn-twitter btn-lg">
                    <i class="fab fa-twitter"></i>
                </a>
            </li>
            <li>
                <a href="https://www.facebook.com/burnleysl/" target="_blank" class="btn btn-icon btn-neutral btn-facebook btn-lg">
                    <i class="fab fa-facebook-square"></i>
                </a>
            </li>
            <li>
                <a href="https://www.linkedin.com/company/burnley-sales-lettings-ltd/" target="_blank" class="btn btn-icon btn-neutral btn-linkedin btn-lg">
                    <i class="fab fa-linkedin"></i>
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/burnleysl/" target="_blank" class="btn btn-icon btn-neutral btn-instagram btn-lg">
                    <i class="fab fa-instagram"></i>
                </a>
            </li>
            </ul>
            <div class="copyright">
                &copy; {{year}}, Burnley Sales & Lettings.
                Site by
                <a href="https://www.eclipse-insights.com/" target="_blank">Eclipse Insights</a>.
            </div>
        </div>
    </footer>
  </div>
</template>
<script>
  export default {
    props: {
      backgroundColor: String,
      type: String
    },
    data(){
      return {
        year: new Date().getFullYear()
      }
    }
  }
</script>
<style>
.tab {
  padding-left: 30px;
}
.tab-phone {
  padding-left: 12.8834px;
}
.tab-email {
  padding-left: 46.5334px;
}
.tab-extra {
  padding-left: 89.5167px;
}
</style>
