<template>
    <div class="wrapper">
        <div class="page-header page-header-medium">
            <parallax class="page-header-image"
                 style="background-image: url('img/banner9.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Accreditations</h1>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto">
                        <!--h2 class="title">Accreditations</h2-->
                        <h5>
                            <ul>
                            <li>The Property Ombudsman (TPOS)</li>
                            <li>Information Commissioner’s Office (ICO)</li>
                            <li>Deposit Protection Scheme (DPS)</li>
                            <li>Money Shield (Client Money Protection)</li>
                            <li>ARLA PropertyMark</li>
                            </ul>
                        </h5>
                    </div>

                    <div class="col-md-8 ml-auto mr-auto" style="padding-top: 50px;">
                        <card type="background" raised class="card-background-product"
                            style="background-image: url('img/background3.jpg')">
                            <h2 class="card-title text-white">Client Money Protection Certificate</h2>
                            <div class="ml-auto mr-auto">
                                <a href="./content/Propertymark_Certificates.pdf" target="_blank">
                                    <n-button type="primary">
                                        View
                                    </n-button>
                                </a>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import { Card, Button } from '@/components';
  export default {
    components: {
      Card,
      [Button.name]: Button
    },
  }
</script>
<style scoped>
a {
    text-decoration: none;
}
</style>